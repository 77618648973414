<template>
  <div ref="thumbnail" class="flex flex-col gap-y-2">
    <div
      :style="posterStyle"
      class="relative h-full w-full thumbnail standard_thumbnail rounded-lg aspect-video bg-center bg-no-repeat bg-contain"
    >
      <div class="w-full flex gap-x-1 items-center pt-2 pe-2 justify-end">
        <UiTagPlus v-if="item.showPlusLabel"></UiTagPlus>
      </div>
      <div
        :style="{ width: `${progress}%` }"
        class="absolute bottom-0 h-[6px] bg-brand-red left-0 rounded-bl-lg"
      ></div>
    </div>
    <h4 class="text-title-small text-white opacity-[0.87] font-bold">
      {{ label }}
    </h4>
  </div>
</template>

<script setup>
import { useIntersectionObserver } from "@vueuse/core";
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const thumbnail = ref();
const isVisible = ref(false);
const posterStyle = ref();

onMounted(() => {
  posterStyle.value = useFallbackImage().style;
});

const imageToShow = computed(() => props.item.images?.horizontalimage);

const { stop } = useIntersectionObserver(
  thumbnail,
  ([{ isIntersecting }], observerElement) => {
    isVisible.value = isIntersecting;
  }
);

watch(isVisible, () => {
  if (isVisible.value && imageToShow.value) {
    posterStyle.value = { backgroundImage: `url('${imageToShow.value}')` };
    stop();
  }
});

const progress = computed(() => {
  return props.item.viewPercentage;
});

const label = computed(() => {
  return props.item.content.name || props.item.name || props.item.seriesName;
});
</script>

<style lang="scss" scoped></style>
