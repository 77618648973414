<template>
  <div
    :style="{
      backgroundImage: `url('${image}')`,
    }"
    class="h-full w-full thumbnail relative thumbnail_with_overlay bg-red-100 rounded-lg aspect-video bg-center bg-no-repeat bg-cover"
  >
    <div class="absolute z-0 top-0 start-0 w-full h-full gradient horizontal">
      <div class="w-full flex gap-x-1 items-center pt-2 pe-2 justify-end">
        <UiTagLive v-if="useIsLive(item)"></UiTagLive>
        <UiTagPlus v-if="item.showPlusLabel"></UiTagPlus>
      </div>
    </div>
    <div
      class="absolute z-10 w-full h-full flex items-center px-4 text-title-medium text-white opacity-[0.87] font-bold"
    >
      <span> {{ label }}</span>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const image = computed(() => {
  const { type, images } = props.item;

  return type == "Genre" ? images.genreimage : images.horizontalimage;
});

const label = computed(() => useFindLabel(props.item));
</script>

<style lang="scss" scoped></style>
