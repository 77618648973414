<template>
  <div ref="thumbnail" class="flex flex-col gap-y-2">
    <div
      :style="posterStyle"
      class="h-full w-full thumbnail relative portrait aspect-[2/3] rounded-lg bg-center bg-no-repeat bg-cover"
    >
      <div class="w-full flex gap-x-1 items-center pt-2 pe-2 justify-end">
        <UiTagLive v-if="useIsLive(item)"></UiTagLive>
        <UiTagPlus v-if="item.showPlusLabel"></UiTagPlus>
      </div>
    </div>
    <h4 class="text-title-small text-white opacity-[0.87] font-bold">
      {{ label }}
    </h4>
  </div>
</template>

<script setup>
import { useIntersectionObserver } from "@vueuse/core";
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  imageToUse: {
    type: String,
    default: () => "appletvimage",
  },
});

const thumbnail = ref();
const isVisible = ref(false);
const posterStyle = ref();

onMounted(() => {
  posterStyle.value = useFallbackImage().style;
});

const imageToShow = computed(() => {
  if (Object.keys(props.item.images ?? {})?.length > 0) {
    return props.item.images[props.imageToUse];
  }
});

const { stop } = useIntersectionObserver(
  thumbnail,
  ([{ isIntersecting }], observerElement) => {
    isVisible.value = isIntersecting;
  }
);

watch(isVisible, () => {
  if (isVisible.value && imageToShow.value) {
    posterStyle.value = { backgroundImage: `url('${imageToShow.value}')` };
    stop();
  }
});

const label = computed(() => useFindLabel(props.item));
</script>

<style lang="scss" scoped></style>
