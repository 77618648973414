<template>
  <div ref="thumbnail" class="flex flex-col justify-center gap-y-2">
    <div
      :style="posterStyle"
      class="h-full w-full thumbnail relative big_subjects rounded-full bg-center bg-no-repeat bg-cover"
    ></div>
    <h4 class="text-title-small text-center text-white opacity-[0.87] font-normal">
      {{ label }}
    </h4>
  </div>
</template>

<script setup>
import { useIntersectionObserver } from "@vueuse/core";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const thumbnail = ref();
const isVisible = ref(false);
const posterStyle = ref();

onMounted(() => {
  posterStyle.value = useFallbackImage().style;
});

const imageToShow = computed(() => props.item.images?.character);

const { stop } = useIntersectionObserver(
  thumbnail,
  ([{ isIntersecting }], observerElement) => {
    isVisible.value = isIntersecting;
  }
);

watch(isVisible, () => {
  if (isVisible.value && imageToShow.value) {
    posterStyle.value = { backgroundImage: `url('${imageToShow.value}')` };
    stop();
  }
});

const label = computed(() => {
  return props.item.name;
});
</script>

<style lang="scss" scoped></style>
