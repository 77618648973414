<template>
  <div
    ref="thumbnail"
    dir="ltr"
    class="thumbnail top_ten w-full h-full flex items-center"
  >
    <img :src="topGraphic" class="w-28 md:w-32 h-auto my-auto" />
    <div
      :style="posterStyle"
      class="relative poster rounded-lg bg-brand-grey-500 bg-center bg-no-repeat bg-contain -m-[16px] md:-m-[24px]"
    >
      <div class="w-full flex gap-x-1 items-center pt-2 pe-2 justify-end">
        <UiTagLive v-if="useIsLive(item)"></UiTagLive>
        <UiTagPlus
          :size="isXs ? 'small' : 'medium'"
          v-if="item.showPlusLabel"
        ></UiTagPlus>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useIntersectionObserver } from "@vueuse/core";

const { isXs } = useBreakpoints();
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

const thumbnail = ref();
const isVisible = ref(false);
const posterStyle = ref();

onMounted(() => {
  posterStyle.value = useFallbackImage().style;
});

const imageToShow = computed(() => props.item.images?.appletvimage);

const { stop } = useIntersectionObserver(
  thumbnail,
  ([{ isIntersecting }], observerElement) => {
    isVisible.value = isIntersecting;
  }
);

watch(isVisible, () => {
  if (isVisible.value && imageToShow.value) {
    posterStyle.value = { backgroundImage: `url('${imageToShow.value}')` };
    stop();
  }
});

const topGraphic = computed(() => {
  return new URL(
    `../../assets/pngs/numbers/${props.index + 1}.png`,
    import.meta.url
  ).href;
});
</script>

<style lang="scss" scoped></style>
