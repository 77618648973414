<template>
  <div ref="thumbnail" class="flex flex-col gap-y-2">
    <div
      :style="posterStyle"
      :class="[thumbClass]"
      class="h-full w-full bg-white/10 thumbnail big_square_thumbnail rounded-lg aspect-square bg-center bg-no-repeat relative"
    >
      <div class="w-full flex gap-x-1 items-center pt-2 pe-2 justify-end">
        <UiTagLive v-if="useIsLive(item)"></UiTagLive>
        <UiTagPlus v-if="item.showPlusLabel"></UiTagPlus>
      </div>
    </div>
    <h4
      v-if="showTitle"
      class="text-title-small text-white opacity-[0.87] font-bold line-clamp-2"
    >
      {{ label }}
    </h4>
  </div>
</template>

<script setup>
import { useIntersectionObserver } from "@vueuse/core";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  showTitle: {
    type: Boolean,
    default: () => true,
  },
  thumbClass: {
    type: String,
    default: () => "bg-cover",
  },
});

const thumbnail = ref();
const isVisible = ref(false);
const posterStyle = ref();

onMounted(() => {
  posterStyle.value = useFallbackImage().style;
});

const imageToShow = computed(() => props.item.images?.squareimage);

const { stop } = useIntersectionObserver(
  thumbnail,
  ([{ isIntersecting }], observerElement) => {
    isVisible.value = isIntersecting;
  }
);

watch(isVisible, () => {
  if (isVisible.value && imageToShow.value) {
    posterStyle.value = { backgroundImage: `url('${imageToShow.value}')` };
    stop();
  }
});

const label = computed(() => useFindLabel(props.item));
</script>

<style lang="scss" scoped></style>
