<template>
  <div>
    <component
      :key="carouselIndex"
      :is="component"
      :thumbnailType="type"
      :item="item"
      :index="carouselIndex"
    ></component>
  </div>
</template>

<script setup>
const props = defineProps({
  type: {
    type: String,
    default: () => "standard_thumbnail",
  },
  item: {
    type: Object,
    required: true,
  },
  carouselIndex: Number,
});

const component = computed(() => {
  switch (props.type) {
    case "thumbnail_with_overlay":
      return resolveComponent("CarouselTypeSmall");
    case "standard_thumbnail":
      return resolveComponent("CarouselTypeStandard");
    case "big_thumbnail":
      return resolveComponent("CarouselTypeBig");
    case "portrait":
      return resolveComponent("CarouselTypePortrait");
    case "thumbnail_with_duration":
      return resolveComponent("CarouselTypeDuration");
    case "thumbnail_with_progress":
      return resolveComponent("CarouselTypeProgress");
    case "top_ten":
      return resolveComponent("CarouselTypeTopTen");
    case "big_subjects":
      return resolveComponent("CarouselTypeBigSubject");
    case "subjects":
      return resolveComponent("CarouselTypeSubject");
    case "big_square_thumbnail":
      return resolveComponent("CarouselTypeBigSquare");
    case "square_thumbnail":
      return resolveComponent("CarouselTypeSquare");
    default:
      return resolveComponent("CarouselTypeStandard");
  }
});
</script>

<script>
export default { name: "CarouselType" };
</script>

<style lang="scss" scoped></style>
